import { Close } from "@mui/icons-material";
import { Card, CardHeader, IconButton, Modal } from "@mui/material";

export default function CustomModal(props: any) {
    return (
        <Modal
            open={props.open}
        >
            <div className="custom-modal">
                <Card className="custom-modal-content">
                    <CardHeader
                        title={
                            <span>
                                {props.title}
                            </span>
                        }
                        action={
                            <IconButton onClick={() => props.onClose(false)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    {props.component}
                </Card>
            </div>
        </Modal>
    )
}