import { createSlice } from '@reduxjs/toolkit'
import { createRole, deleteRole, editRole, getMenu } from './role.api'

export interface RoleState {
    loading: boolean
}
const initialState: RoleState = {
    loading: false
}

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRole.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRole.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editRole.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRole.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRole.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getMenu.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getMenu.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getMenu.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default roleSlice.reducer