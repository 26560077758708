import { Autocomplete, Grid, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../state";
import notificationError from "./notificationError";
import { useDispatch } from "react-redux";
import { setLoading } from "../containers/containers.reducer";

export default function FormAutocomplete(props: any) {

    const dispatch = useDispatch()

    const [options, setOptions] = useState<any>([])
    const [optionsSelected, setOptionsSelected] = useState<any>({})

    useEffect(() => {
        dispatch(setLoading(true))
        axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + props.url
        })
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setLoading(false))
                    setOptions(response.data.data)
                    if (props.value !== "") setOptionsSelected(response.data.data.filter((data: any) => data.id === props.value)[0])
                }
                else {
                    dispatch(setLoading(false))
                    notificationError(response)
                }
            })
            .catch((error: any) => {
                dispatch(setLoading(false))
                notificationError(error.response)
            })
    }, [props.url])

    const handleChange = (e: any, value: any) => {
        setOptionsSelected(value)
        props.onChange({
            target: {
                name: props.name,
                value: value.id
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <b>{props.label}</b>
            </Grid>
            <Grid item md={9}>
                <Autocomplete
                    fullWidth
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={(option: any) => { return option.name || "" }}
                    getOptionDisabled={(option: any) => { return props.idAllowed ? !props.idAllowed.includes(option.id) : false || props.idDisabled ? props.idDisabled.includes(option.id) : false }}
                    value={optionsSelected}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} size="small" placeholder={"Choose " + props.label} disabled={props.disabled} required />}
                    disabled={props.disabled}
                />
            </Grid>
        </Grid>
    )
}