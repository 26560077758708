import { Grid, TextField } from "@mui/material";

export default function FormNumber(props: any) {
    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <b>{props.label}</b>
            </Grid>
            <Grid item md={9}>
                <TextField
                    fullWidth
                    type="number"
                    size="small"
                    placeholder={props.label}
                    name={props.name}
                    value={props.value || 0}
                    onChange={props.onChange}
                    required={props.required}
                    inputProps={{ max: (isNaN(props.max) ? undefined : props.max), step: (isNaN(props.step) ? undefined : props.step) }}
                    disabled={props.disabled}
                />
            </Grid>
        </Grid>
    )
}