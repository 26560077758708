import { Lock } from "@mui/icons-material";
import { Avatar, Backdrop, Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notificationError from "../../../shared/notificationError";
import { RootState } from "../../../store";
import { login } from "../login.api";

export default function Login() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { loading } = useSelector((state: RootState) => state.login)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch<any>(login({
            username: username,
            password: password
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    localStorage.setItem('bat-token', response.data.data.uuid)
                    localStorage.setItem('bat-user', JSON.stringify({
                        id: response.data.data.id,
                        name: response.data.data.name,
                        role_name: response.data.data.role_name,
                        role_access: response.data.data.role_access
                    }))
                    navigate('/')
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Lock />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            type="password"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Typography variant="h6">
                            JTM Group 2022
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>

    )
}