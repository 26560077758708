import { createSlice } from '@reduxjs/toolkit'

export interface ContainersState {
    loading: boolean
}
const initialState: ContainersState = {
    loading: false
}

export const containersSlice = createSlice({
    name: 'containers',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => { },
})

export const {
    setLoading
} = containersSlice.actions;

export default containersSlice.reducer