import { Button, Container, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import checkAccess from "../../../../shared/checkAccess";
import CustomModal from "../../../../shared/CustomModal";
import CustomTable from "../../../../shared/CustomTable";
import notificationError from "../../../../shared/notificationError";
import notificationSuccess from "../../../../shared/notificationSuccess";
import { deleteTransactionNumber } from "../transactionNumber.api";
import TransactionNumberForm from "./TransactionNumberForm";

export default function TransactionNumber() {

    const dispatch = useDispatch()

    const [dataSelected, setDataSelected] = useState<any>({})
    const [modalEdit, setModalEdit] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [timeGetData, setTimeGetData] = useState<any>(undefined)

    const handleCloseModal = (isReload: boolean) => {
        setModalAdd(false)
        setModalEdit(false)
        if (isReload) {
            setTimeGetData(new Date().getTime())
        }
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            icon: "question",
            title: "Yakin ingin menghapus data?",
            showCancelButton: true
        })
            .then(response => {
                if (response.isConfirmed) {
                    dispatch<any>(deleteTransactionNumber(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("Berhasil menghapus data Nomor Awal Transaksi")
                                setTimeGetData(new Date().getTime())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                onClose={handleCloseModal}
                title="Tambah Nomor Awal Transaksi"
                component={
                    <TransactionNumberForm onClose={handleCloseModal} />
                }
            />
            <CustomModal
                open={modalEdit}
                onClose={handleCloseModal}
                title="Edit Nomor Awal Transaksi"
                component={
                    <TransactionNumberForm onClose={handleCloseModal} data={dataSelected} />
                }
            />
            <CustomTable
                url={"/transactionNumber"}
                paginations={true}
                columns={[
                    {
                        name: "Nilai",
                        key: "value"
                    },
                    {
                        name: "Tanggal Mulai Berlaku",
                        key: "start_date",
                        customRender: (props: any) => {
                            return (
                                <td>
                                    {moment(props.data.start_date).format("YYYY-MM-DD")}
                                </td>
                            )
                        }
                    }
                ]}
                headerActions={(props: any) => (
                    <Fragment>
                        {checkAccess("CREATE") &&
                            <Button
                                variant="contained"
                                onClick={() => { setModalAdd(true) }}
                            >
                                Tambah Nomor Awal Transaksi
                            </Button>
                        }
                    </Fragment>
                )}
                rowActions={(props: any) => (
                    <Fragment>
                        {checkAccess("EDIT") &&
                            <ListItemButton onClick={() => { setDataSelected(props.data); setModalEdit(true) }}>
                                <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                        }
                        {checkAccess("DELETE") &&
                            <ListItemButton onClick={() => handleDelete(props.data.id)}>
                                <ListItemText>Hapus</ListItemText>
                            </ListItemButton>
                        }
                    </Fragment>
                )}
                timeGetData={timeGetData}
            />
        </Container>
    )
}