import { Container } from "@mui/material";
import { Fragment } from "react";
import checkAccess from "../../../shared/checkAccess";
import DashboardBooked from "./DashboardBooked";
import DashboardBooking from "./DashboardBooking";

export default function Dashboard() {

    return (
        <Container className="dashboard" maxWidth="xl">
            {checkAccess("BOOKING") && (
                <Fragment>
                    <DashboardBooking />
                    <br />
                </Fragment>
            )}
            <DashboardBooked />
        </Container >
    )
}