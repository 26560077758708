import { Inventory, Dashboard as DashboardIcon, DataArray, Receipt } from "@mui/icons-material";

import Dashboard from "./pages/dashboard/component/Dashboard";

import Transaction from "./pages/transaction/component/Transaction";

import Price from "./pages/master/price/component/Price";
import Room from "./pages/master/room/component/Room";
import TransactionNumber from "./pages/master/transactionnumber/component/TransactionNumber";
import Employee from "./pages/master/employee/component/Employee";
import Role from "./pages/master/role/component/Role";

const routeList = [
    {
        name: "Dashboard",
        path: "/dashboard",
        icon: <DashboardIcon />,
        component: <Dashboard />
    },
    {
        name: "Transaksi",
        path: "/transaction",
        icon: <Receipt />,
        component: <Transaction />
    },
    {
        name: "Master Data",
        path: "/master",
        icon: <DataArray />,
        child: [
            {
                name: "Harga",
                path: "/price",
                component: <Price />
            },
            {
                name: "Kamar",
                path: "/room",
                component: <Room />
            },
            {
                name: "Nomor Awal Transaksi",
                path: "/transactionnumber",
                component: <TransactionNumber />
            },
            {
                name: "Pegawai",
                path: "/employee",
                component: <Employee />
            },
            {
                name: "Akses",
                path: "/role",
                component: <Role />
            }
        ]
    }
]

export default routeList 