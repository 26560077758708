import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import store from "./store";
import routeList from "./routeList";
import "./assets/style.scss"
import { useEffect, useState } from "react";

import Login from "./pages/login/component/Login";
import Layout from "./containers/components/Layout";

import TransactionSheet from "./pages/transaction/component/TransactionSheet";
import TransactionStruck from "./pages/transaction/component/TransactionStruck";

function App() {

  const navigate = useNavigate()

  const [pathAndComponentList, setPathAndComponentList] = useState<any>([])

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1fdd7b",
        contrastText: "#ffffff"
      },
      secondary: {
        main: "#cbffef",
        contrastText: "#1fdd7b"
      },
      action: {
        focus: "#cbffef"
      }
    },
    shadows: ["none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none"],
    shape: {
      borderRadius: 10
    }
  })

  useEffect(() => {
    let token = localStorage.getItem("bat-token")
    if (token) {

    }
    else {
      navigate('/login')
    }
    let pathAndComponentListNew: any[] = []
    routeList.map((route: any) => {
      if (route.child) {
        route.child.map((child: any) => {
          pathAndComponentListNew.push({ path: route.path + child.path, component: child.component })
          return null
        })
      }
      else if (route.path) {
        pathAndComponentListNew.push({ path: route.path, component: route.component })
      }
      return null
    })
    setPathAndComponentList(pathAndComponentListNew)
  }, [])

  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Routes>
          {pathAndComponentList.map((item: any, i: number) => (
            <Route key={i} path={item.path} element={<Layout component={item.component} />} />
          ))}
          <Route path="/login" element={<Login />} />
          <Route path="/transaction/print" element={<TransactionSheet />} />
          <Route path="/transaction/struck" element={<TransactionStruck />} />
          <Route path="/" element={<Navigate to={routeList[0].path} />} />
        </Routes>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
