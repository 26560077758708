import { createSlice } from '@reduxjs/toolkit'
import { createRoom, deleteRoom, editRoom, getRoomAvailable } from './room.api'

export interface RoomState {
    loading: boolean
}
const initialState: RoomState = {
    loading: false
}

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getRoomAvailable.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRoomAvailable.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getRoomAvailable.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createRoom.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRoom.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRoom.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteRoom.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRoom.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRoom.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editRoom.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRoom.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRoom.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default roomSlice.reducer