import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import FormText from "../../../shared/FormText";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import { createTransaction, getTransactionActive } from "../dashboard.api";

export default function DashboardBooking() {

    const dispatch = useDispatch()

    const [isInputBooking, setIsInputBooking] = useState(false)
    const [values, setValues] = useState<any>({})

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleToggleForm = () => {
        setIsInputBooking(!isInputBooking)
        setValues({})
    }

    const handleSubmit = () => {
        dispatch<any>(createTransaction({
            ...values,
            employee_a_id: JSON.parse(localStorage.getItem('bat-user') || "{}").id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("Berhasil menambah data")
                        .then(() => {
                            window.open(`/#/transaction/struck?id=${response.data.data.insertId}&is_cashier=0`, '__blank')
                            setValues({})
                            setIsInputBooking(false)
                            dispatch<any>(getTransactionActive())
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <Card>
            <CardHeader title="Booking" />
            <CardContent>
                {isInputBooking
                    ? (
                        <Fragment>
                            <FormText label="Nama" name="name" value={values.name} onChange={handleChangeValues} />
                            <br />
                            <FormText label="Telepon" name="phone" value={values.phone} onChange={handleChangeValues} />
                            <br />
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <Button variant="outlined" fullWidth onClick={handleToggleForm}>Batal</Button>
                                </Grid>
                                <Grid item md={6}>
                                    <Button variant="contained" fullWidth onClick={handleSubmit} disabled={!(Boolean(values.name) && Boolean(values.phone))}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                    : (
                        <Fragment>
                            <Button variant="contained" fullWidth onClick={handleToggleForm}>Input Booking</Button>
                        </Fragment>
                    )
                }
            </CardContent>
        </Card >
    )
}