import { AccountCircle, ExpandMore } from "@mui/icons-material";
import { IconButton, List, ListItemButton, ListItemText, Popover } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoading from "../../shared/CustomLoading";

export default function Header() {

    const navigate = useNavigate()

    const [popAnchorAction, setPopAnchorAction] = useState<HTMLButtonElement | null>(null);

    const handleLogout = () => {
        localStorage.removeItem('bat-token')
        localStorage.removeItem('bat-user')
        navigate("/login")
    }

    return (
        <header>
            <CustomLoading />
            <Popover
                open={Boolean(popAnchorAction)}
                anchorEl={popAnchorAction}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setPopAnchorAction(null)}
            >
                <List>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemText>Logout</ListItemText>
                    </ListItemButton>
                </List>
            </Popover>
            <div className="title">
            </div>
            <div className="profile">
                <div className="profile">
                    <AccountCircle />
                </div>
                <div>
                    <b className="name">{JSON.parse(localStorage.getItem('bat-user') || "{}").name}</b>
                    <span className="role">{JSON.parse(localStorage.getItem('bat-user') || "{}").role_name}</span>
                </div>
                <IconButton size="small" onClick={e => setPopAnchorAction(e.currentTarget)}>
                    <ExpandMore />
                </IconButton>
            </div>
        </header>
    )
}