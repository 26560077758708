import { createSlice } from '@reduxjs/toolkit'
import { createTransactionNumber, deleteTransactionNumber, editTransactionNumber } from './transactionNumber.api'

export interface TransactionNumberState {
    loading: boolean
}
const initialState: TransactionNumberState = {
    loading: false
}

export const transactionNumberSlice = createSlice({
    name: 'transactionNumber',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createTransactionNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTransactionNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTransactionNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteTransactionNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTransactionNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTransactionNumber.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editTransactionNumber.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTransactionNumber.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTransactionNumber.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default transactionNumberSlice.reducer