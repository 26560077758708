import { Print } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import checkAccess from "../../../shared/checkAccess";
import CustomModal from "../../../shared/CustomModal";
import notificationError from "../../../shared/notificationError";
import { RootState } from "../../../store";
import { getPrice } from "../../master/price/price.api";
import { getRoomAvailable } from "../../master/room/room.api";
import { controlFinish, controlStart, getTransactionActive } from "../dashboard.api";

export default function DashboardBooked() {

    const { activeTransactionList } = useSelector((state: RootState) => state.dashboard)

    const [time, setTime] = useState(0)
    const [modalCheckIn, setModalCheckin] = useState(false)
    const [transactionSelected, setTransactionSelected] = useState<any>({})
    const [roomList, setRoomList] = useState<any>([])
    const [roomId, setRoomId] = useState(0)
    const [priceList, setPriceList] = useState<any>([])
    const [priceSelected, setPriceSelected] = useState<any>({})

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch<any>(getTransactionActive())
        let i = setInterval(() => {
            setTime(new Date().getTime())
        }, 100)
        let j = setInterval(() => {
            dispatch<any>(getTransactionActive())
        }, 10000)
        return () => {
            clearInterval(i)
            clearInterval(j)
        };
    }, [])

    const handleControlStart = (transaction: any) => {
        setTransactionSelected(transaction)
        setModalCheckin(true)
        dispatch<any>(getRoomAvailable())
            .unwrap()
            .then((response: any) => {
                setRoomList(response.data.data)
            })
        dispatch<any>(getPrice())
            .unwrap()
            .then((response: any) => {
                setPriceList(response.data.data)
            })
    }

    const handleSubmitRoom = () => {
        dispatch<any>(controlStart({
            id: transactionSelected.id,
            room_id: roomId,
            time: priceSelected.hour,
            price: priceSelected.price,
            employee_b_id: JSON.parse(localStorage.getItem('bat-user') || "{}").id
        }))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch<any>(getTransactionActive())
                    window.open(`/#/transaction/struck?id=${transactionSelected.id}&is_cashier=1`, '__blank')
                    setModalCheckin(false)
                    setTransactionSelected({})
                    setRoomList([])
                    setRoomId(0)
                }
                else {
                    notificationError(response)
                }
            })
    }

    const handleControlFinish = (id: number) => {
        Swal.fire({
            title: "Selesaikan transaksi",
            text: "tindakan ini tidak bisa dibatalkan",
            icon: "question",
            showCancelButton: true
        })
            .then((response) => {
                if (response.isConfirmed) {
                    dispatch<any>(controlFinish(id))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                dispatch<any>(getTransactionActive())
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
            })
    }

    const transactionCard = (transaction: any) => {
        let timer = ``
        if (transaction.ts_start === null) {
            timer = `${String(Math.floor(transaction.time)).padStart(2, '0')}:00:00`
        }
        else {
            let duration = moment.duration(((transaction.time) * 3600000) - (moment().diff(moment(transaction.ts_start))))
            timer = `${String((duration.days() * 24) + duration.hours()).padStart(2, '0')}:${String(duration.minutes()).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`
            if (duration.seconds() <= 0) {
                timer = `00:00:00`
            }
        }
        return (
            <Grid item xl={2} lg={3} md={4} sm={12} key={transaction.id}>
                <Card>
                    <CardContent className="timer">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Nomor</td>
                                    <td>:</td>
                                    <td>{transaction.no}</td>
                                </tr>
                                <tr>
                                    <td>Nama</td>
                                    <td>:</td>
                                    <td>{transaction.name}</td>
                                </tr>
                                <tr>
                                    <td>Telepon</td>
                                    <td>:</td>
                                    <td>{transaction.phone}</td>
                                </tr>
                                <tr>
                                    <td>Kamar</td>
                                    <td>:</td>
                                    <td>{transaction.room_name}</td>
                                </tr>
                                <tr>
                                    <td>Satpam</td>
                                    <td>:</td>
                                    <td>{transaction.employee_a_name}</td>
                                </tr>
                                <tr>
                                    <td>Kasir</td>
                                    <td>:</td>
                                    <td>{transaction.employee_b_name}</td>
                                </tr>
                                <tr>
                                    <td>Harga</td>
                                    <td>:</td>
                                    <td>{transaction.price}</td>
                                </tr>
                            </tbody>
                        </table>
                        <span className="time">
                            {timer}
                        </span>
                        <Grid container spacing={1}>
                            <Grid item md={9}>
                                {transaction.ts_start === null
                                    ? checkAccess("TIMER_CONTROL_START") && (
                                        <Button fullWidth variant="contained" className="start" onClick={() => handleControlStart(transaction)}>Check In</Button>
                                    )
                                    : checkAccess("TIMER_CONTROL_STOP") && (
                                        <Button fullWidth variant="contained" className="finish" onClick={() => handleControlFinish(transaction.id)}>Check Out</Button>
                                    )
                                }
                            </Grid>
                            <Grid item md={3}>
                                <IconButton onClick={() => window.open(`/#/transaction/struck?id=${transaction.id}&is_cashier=1`, '__blank')}>
                                    <Print />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            <CustomModal
                open={modalCheckIn}
                onClose={() => setModalCheckin(false)}
                title="Pilih Kamar"
                component={
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Nomor</td>
                                                <td>:</td>
                                                <td>{transactionSelected.no}</td>
                                            </tr>
                                            <tr>
                                                <td>Nama</td>
                                                <td>:</td>
                                                <td>{transactionSelected.name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                                <Grid item md={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        label="Kamar"
                                        onChange={(e: any) => setRoomId(e.target.value)}
                                    >
                                        {roomList.map((room: any) => (
                                            <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>
                                        ))}
                                    </TextField>
                                    <br />
                                    <br />
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        label="Waktu"
                                        onChange={(e: any) => setPriceSelected(priceList.filter((price: any) => price.id === e.target.value)[0])}
                                    >
                                        {priceList.map((price: any) => (
                                            <MenuItem key={price.id} value={price.id}>{price.hour} jam, {price.price}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={12} style={{ textAlign: "right" }}>
                                    <Button variant="contained" onClick={handleSubmitRoom}>Submit</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }
            />
            {activeTransactionList.map((transaction: any) => transactionCard(transaction))}
        </Grid>
    )
}