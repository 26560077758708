import { createSlice } from '@reduxjs/toolkit'
import { controlFinish, controlStart, createTransaction, getTransactionActive } from './dashboard.api'

export interface DashboardState {
    loading: boolean,
    activeTransactionList: any
}
const initialState: DashboardState = {
    loading: false,
    activeTransactionList: []
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTransaction.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTransaction.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getTransactionActive.pending, (state, action) => {
        })
        builder.addCase(getTransactionActive.fulfilled, (state, action) => {
            state.activeTransactionList = action.payload.data.data
        })
        builder.addCase(getTransactionActive.rejected, (state, action) => {
        })

        builder.addCase(controlStart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlStart.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlStart.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(controlFinish.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(controlFinish.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(controlFinish.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default dashboardSlice.reducer