import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createEmployee = createAsyncThunk('createEmployee', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/employee',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteEmployee = createAsyncThunk('deleteEmployee', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/employee?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editEmployee = createAsyncThunk('editEmployee', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/employee',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})