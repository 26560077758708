import { Check } from "@mui/icons-material"
import { Button, Card, CardContent } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormDate from "../../../../shared/FormDate"
import FormNumber from "../../../../shared/FormNumber"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { createTransactionNumber, editTransactionNumber } from "../transactionNumber.api"

export default function TransactionNumberForm(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        value: ""
    })

    useEffect(() => {
        if (Boolean(props.data)) {
            setValues({
                id: props.data.id,
                value: props.data.value,
                start_date: moment(props.data.start_date).format("YYYY-MM-DD")
            })
        }
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (!Boolean(props.data)) {
            dispatch<any>(createTransactionNumber(values))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("Berhasil menambah data")
                            .then(() => {
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
        else {
            dispatch<any>(editTransactionNumber(values))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("Berhasil menyimpan data")
                            .then(() => {
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <FormNumber label="Nilai" name="value" value={values.value} onChange={handleChangeValues} required />
                    <br />
                    <FormDate label="Tanggal Mulai Berlaku" name="start_date" value={values.start_date} onChange={handleChangeValues} required />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Batal
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Simpan
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}