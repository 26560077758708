import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../store";
import { getTransaction } from "../transaction.api";

export default function TransactionSheet(props: any) {

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const { transactionList } = useSelector((state: RootState) => state.transaction)

    const [dateStart, setDateStart] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [type, setType] = useState("ONE")

    useEffect(() => {
        if (Boolean(props.dateStart)) {
            setDateStart(props.dateStart)
            setDateEnd(props.dateEnd)
            setType(props.type)
        }
        else {
            setDateStart(searchParams.get("dateStart") || "")
            setDateEnd(searchParams.get("dateEnd") || "")
            setType(searchParams.get("type") || "")
        }
    }, [props])

    useEffect(() => {
        if (dateStart !== "" && dateEnd !== "") {
            dispatch<any>(getTransaction({
                sortColumn: type === "ROOM" ? "room_id, ts_created" : "ts_created",
                datestart: dateStart,
                dateend: dateEnd
            }))
                .then(() => {
                    if (!Boolean(props.dateStart)) {
                        let i = setTimeout(() => {
                            window.print()
                            clearTimeout(i)
                        }, 100)
                    }
                })
        }
    }, [dateStart, dateEnd, type])

    const columns = [
        {
            name: "Tanggal",
            key: "ts_created",
            customRender: (props: any) => (
                <td>
                    {moment(props.data.ts_created).format('YYYY-MM-DD HH:mm:ss')}
                </td>
            )
        },
        {
            name: "Nomor Transaksi",
            key: "no"
        },
        {
            name: "Nama",
            key: "name"
        },
        {
            name: "Telepon",
            key: "phone"
        },
        {
            name: "Satpam",
            key: "employee_a_name"
        },
        {
            name: "Kasir",
            key: "employee_b_name"
        },
        {
            name: "Kamar",
            key: "room_name"
        },
        {
            name: "Waktu (jam)",
            key: "time"
        },
        {
            name: "Waktu Check In",
            key: "ts_start",
            customRender: (props: any) => (
                <td>
                    {moment(props.data.ts_start).format('YYYY-MM-DD HH:mm:ss')}
                </td>
            )
        },
        {
            name: "Waktu Check Out",
            key: "ts_end",
            customRender: (props: any) => (
                <td>
                    {moment(props.data.ts_end).format('YYYY-MM-DD HH:mm:ss')}
                </td>
            )
        },
        {
            name: "Harga",
            key: "price"
        }
    ]

    const sumRow = (data: any) => {
        // per transaksi
        if (type === "ONE" &&
            (
                transactionList.indexOf(data) + 1 === transactionList.length
            )
        ) {
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.reduce((partialSum: number, a: any) => partialSum + a.time, 0)}</b></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.reduce((partialSum: number, a: any) => partialSum + a.price, 0)}</b></td>
                </tr>
            )
        }
        // per hari
        else if (
            type === "DAY" &&
            (
                (
                    transactionList[transactionList.indexOf(data) + 1] &&
                    (moment(transactionList[transactionList.indexOf(data) + 1].ts_created).format('DD') !== moment(data.ts_created).format('DD'))
                ) ||
                (transactionList.indexOf(data) + 1 === transactionList.length)
            )
        ) {
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY-MM-DD") === moment(data.ts_created).format("YYYY-MM-DD")).reduce((partialSum: number, a: any) => partialSum + a.time, 0)}</b></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY-MM-DD") === moment(data.ts_created).format("YYYY-MM-DD")).reduce((partialSum: number, a: any) => partialSum + a.price, 0)}</b></td>
                </tr>
            )
        }
        // per minggu
        else if (
            type === "WEEK" &&
            (
                (
                    transactionList[transactionList.indexOf(data) + 1] &&
                    (moment(transactionList[transactionList.indexOf(data) + 1].ts_created).format('WW') !== moment(data.ts_created).format('WW'))
                ) ||
                (transactionList.indexOf(data) + 1 === transactionList.length)
            )
        ) {
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY-WW") === moment(data.ts_created).format("YYYY-WW")).reduce((partialSum: number, a: any) => partialSum + a.time, 0)}</b></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY-WW") === moment(data.ts_created).format("YYYY-WW")).reduce((partialSum: number, a: any) => partialSum + a.price, 0)}</b></td>
                </tr>
            )
        }
        // per bulan
        else if (
            type === "MONTH" &&
            (
                (
                    transactionList[transactionList.indexOf(data) + 1] &&
                    (moment(transactionList[transactionList.indexOf(data) + 1].ts_created).format('MM') !== moment(data.ts_created).format('MM'))
                ) ||
                (transactionList.indexOf(data) + 1 === transactionList.length)
            )
        ) {
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY-MM") === moment(data.ts_created).format("YYYY-MM")).reduce((partialSum: number, a: any) => partialSum + a.time, 0)}</b></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY-MM") === moment(data.ts_created).format("YYYY-MM")).reduce((partialSum: number, a: any) => partialSum + a.price, 0)}</b></td>
                </tr>
            )
        }
        // per tahun
        else if (
            type === "YEAR" &&
            (
                (
                    transactionList[transactionList.indexOf(data) + 1] &&
                    (moment(transactionList[transactionList.indexOf(data) + 1].ts_created).format('YYYY') !== moment(data.ts_created).format('YYYY'))
                ) ||
                (transactionList.indexOf(data) + 1 === transactionList.length)
            )
        ) {
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY") === moment(data.ts_created).format("YYYY")).reduce((partialSum: number, a: any) => partialSum + a.time, 0)}</b></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => moment(d.ts_created).format("YYYY") === moment(data.ts_created).format("YYYY")).reduce((partialSum: number, a: any) => partialSum + a.price, 0)}</b></td>
                </tr>
            )
        }
        // per produk
        else if (
            type === "ROOM" &&
            (
                (
                    transactionList[transactionList.indexOf(data) + 1] &&
                    (transactionList[transactionList.indexOf(data) + 1].room_id !== data.room_id)
                ) ||
                (transactionList.indexOf(data) + 1 === transactionList.length)
            )
        ) {
            return (
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => d.room_id === data.room_id).reduce((partialSum: number, a: any) => partialSum + a.time, 0)}</b></td>
                    <td></td>
                    <td></td>
                    <td><b>{transactionList.filter((d: any) => d.room_id === data.room_id).reduce((partialSum: number, a: any) => partialSum + a.price, 0)}</b></td>
                </tr>
            )
        }
        return null
    }

    return (
        <div className="transaction">
            <table>
                <thead>
                    <tr>
                        {columns.map((column: any, i: number) => (
                            <td key={i}>{column.name}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {transactionList.map((data: any, i: number) => {
                        return (
                            <Fragment key={i}>
                                <tr>
                                    {columns.map((column: any, j: number) => (
                                        column.customRender
                                            ? (
                                                <column.customRender key={j} data={data} />
                                            )
                                            : (
                                                <td key={j}>{data[column.key]}</td>
                                            )
                                    ))}
                                </tr>
                                {sumRow(data)}
                            </Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}