import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../state";

export const getTransaction = createAsyncThunk('getTransaction', async (params: any) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/transaction',
            params: params
        })
        return response
    } catch (error: any) {
        return error.response
    }
})