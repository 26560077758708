import { createSlice } from '@reduxjs/toolkit'
import { getTransaction } from './transaction.api'

export interface TransactionState {
    loading: boolean,
    transactionList: any
}
const initialState: TransactionState = {
    loading: false,
    transactionList: []
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTransaction.fulfilled, (state, action) => {
            state.transactionList = action.payload.data.data
            state.loading = false
        })
        builder.addCase(getTransaction.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default transactionSlice.reducer