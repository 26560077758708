import { createSlice } from '@reduxjs/toolkit'
import { createPrice, deletePrice, editPrice, getPrice } from './price.api'

export interface PriceState {
    loading: boolean
}
const initialState: PriceState = {
    loading: false
}

export const priceSlice = createSlice({
    name: 'price',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getPrice.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getPrice.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getPrice.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createPrice.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createPrice.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createPrice.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deletePrice.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deletePrice.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deletePrice.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editPrice.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editPrice.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editPrice.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default priceSlice.reducer