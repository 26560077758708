import { Check } from "@mui/icons-material"
import { Button, Card, CardContent, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { createRole, editRole } from "../role.api"
import RoleFormAccess from "./RoleFormAccess"

export default function RoleForm(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        name: "",
        access: ""
    })

    useEffect(() => {
        if (Boolean(props.data)) {
            setValues({
                id: props.data.id,
                name: props.data.name,
                access: props.data.access || '{}'
            })
        }
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (!Boolean(props.data)) {
            dispatch<any>(createRole(values))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("Berhasil menambah data")
                            .then(() => {
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
        else {
            dispatch<any>(editRole(values))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("Berhasil menyimpan data")
                            .then(() => {
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <FormText label="Nama" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <Typography variant="h6" align="center">Access</Typography>
                    <br />
                    {values.id && <RoleFormAccess value={values.access} onChange={handleChangeValues} />}
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Batal
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Simpan
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}