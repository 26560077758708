import { createSlice } from '@reduxjs/toolkit'
import { createEmployee, deleteEmployee, editEmployee } from './employee.api'

export interface EmployeeState {
    loading: boolean
}
const initialState: EmployeeState = {
    loading: false
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(createEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createEmployee.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteEmployee.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(editEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editEmployee.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default employeeSlice.reducer