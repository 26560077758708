import { Backdrop, CircularProgress } from "@mui/material"
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function CustomLoading() {

    const loadingContainers = useSelector((state: RootState) => state.containers).loading

    const loadingDashboard = useSelector((state: RootState) => state.dashboard).loading

    const loadingTransaction = useSelector((state: RootState) => state.transaction).loading

    const loadingPrice = useSelector((state: RootState) => state.price).loading
    const loadingRoom = useSelector((state: RootState) => state.room).loading
    const loadingTransactionNumber = useSelector((state: RootState) => state.transactionnumber).loading
    const loadingEmployee = useSelector((state: RootState) => state.employee).loading
    const loadingRole = useSelector((state: RootState) => state.role).loading

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3000 }}
            open={
                loadingContainers ||

                loadingDashboard ||

                loadingTransaction ||

                loadingPrice ||
                loadingRoom ||
                loadingTransactionNumber ||
                loadingEmployee ||
                loadingRole
            }
        >
            <CircularProgress color="inherit" />
        </Backdrop >
    )
}