import { Check } from "@mui/icons-material"
import { Button, Card, CardContent } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormText from "../../../../shared/FormText"
import notificationError from "../../../../shared/notificationError"
import notificationSuccess from "../../../../shared/notificationSuccess"
import { createRoom, editRoom } from "../room.api"

export default function RoomForm(props: any) {

    const dispatch = useDispatch()

    const [values, setValues] = useState<any>({
        name: ""
    })

    useEffect(() => {
        if (Boolean(props.data)) {
            setValues({
                id: props.data.id,
                name: props.data.name
            })
        }
    }, [])

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (!Boolean(props.data)) {
            dispatch<any>(createRoom(values))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("Berhasil menambah data")
                            .then(() => {
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
        else {
            dispatch<any>(editRoom(values))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        notificationSuccess("Berhasil menyimpan data")
                            .then(() => {
                                props.onClose(true)
                            })
                    }
                    else {
                        notificationError(response)
                    }
                })
        }
    }

    return (
        <Card>
            <CardContent className="custom-form">
                <form onSubmit={handleSubmit}>
                    <FormText label="Nama" name="name" value={values.name} onChange={handleChangeValues} required />
                    <br />
                    <div className="form-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.onClose(false)}
                        >
                            Batal
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            type="submit"
                        >
                            Simpan
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card >
    )
}