import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const createRole = createAsyncThunk('createRole', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/role',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteRole = createAsyncThunk('deleteRole', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/role?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editRole = createAsyncThunk('editRole', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/role',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const getMenu = createAsyncThunk('getMenu', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/menu'
        })
        return response
    } catch (error: any) {
        return error.response
    }
})