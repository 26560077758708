import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";

export const getRoomAvailable = createAsyncThunk('getRoomAvailable', async () => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room/available'
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const createRoom = createAsyncThunk('createRoom', async (data: any) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const deleteRoom = createAsyncThunk('deleteRoom', async (id: string) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room?id=' + id,
        })
        return response
    } catch (error: any) {
        return error.response
    }
})

export const editRoom = createAsyncThunk('editRoom', async (data: any) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('bat-token')
            },
            url: baseUrl + '/room',
            data: data
        })
        return response
    } catch (error: any) {
        return error.response
    }
})